import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Product {
    id: number;
    name: string;
    quantity: number;
    paths?: string[];
    thumbnail?: string;
}

interface CartState {
    items: Product[];
    isCart: boolean;
}

const initialState: CartState = {
    items: [],
    isCart: false,
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addToCart: (state, action: PayloadAction<Product>) => {
            const newItem = action.payload;
    
            if ([5, 6, 8].includes(newItem.id)) {
                const itemExists = state.items.some(item => 
                    item.id === newItem.id && 
                    JSON.stringify(item.paths) === JSON.stringify(newItem.paths)
                );
    
                if (!itemExists) {
                    newItem.quantity = 1;
                    state.items.push(newItem);
                }
            } else {
                const itemIndex = state.items.findIndex(item => item.id === newItem.id);
    
                if (itemIndex >= 0) {
                    state.items[itemIndex].quantity += newItem.quantity;
    
                    const currentPaths = state.items[itemIndex].paths || [];
                    if (newItem.paths) {
                        state.items[itemIndex].paths = [...currentPaths, ...newItem.paths];
                    }
                } else {
                    state.items.push(newItem); 
                }
            }
        },
        
        toggleCartVisibility: (state) => {
            state.isCart = !state.isCart;
        },

        clearCart: (state) => {
            state.items = [];
        },

        removeFromCart: (state, action: PayloadAction<{ id: number; path?: string }>) => {
            const { id, path } = action.payload;

            if (path) {
                const itemIndex = state.items.findIndex(
                    item => item.id === id && item.paths && item.paths.includes(path)
                );

                if (state.items[itemIndex]?.paths?.length === 1) {
                    state.items.splice(itemIndex, 1);
                } else {
                    state.items[itemIndex].paths = state.items[itemIndex].paths?.filter(
                        itemPath => itemPath !== path
                    );
                }
                
            } else {
                state.items = state.items.filter(item => item.id !== id);
            }
        },

        changeItemQuantity: (state, action: PayloadAction<{ id: number; quantity: number }>) => {
            const { id, quantity } = action.payload;
            const itemIndex = state.items.findIndex(item => item.id === id);
            
            if (itemIndex >= 0 && quantity > 0) {
                state.items[itemIndex].quantity = quantity;
            }
        },
    },
});

export const { addToCart, toggleCartVisibility, clearCart, removeFromCart, changeItemQuantity } = cartSlice.actions;
export default cartSlice.reducer;
