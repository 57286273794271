import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import { updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import BodyLoading from '../../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import changeStyles from './change_styles.module.scss';

const EmailChange: FC = () => {

    const [currentEmail, setCurrentEmail] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [confirmEmail, setConfirmEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChangeEmail = async (newEmail: string) => {
        if (!currentEmail || !currentPassword || !email || !confirmEmail) {
            setErrorMessage("記入漏れがあります。");
            return;
        }

        if (email !== confirmEmail) {
            setErrorMessage("入力されたメールアドレスと確認用のメールアドレスが一致しません。");
            return;
        }

        setIsLoading(true);

        if (!auth.currentUser) {
            setIsLoading(false);
            return;
        }

        const credential = EmailAuthProvider.credential(currentEmail, currentPassword);

        try {
            await reauthenticateWithCredential(auth.currentUser, credential);

            await updateEmail(auth.currentUser, newEmail);
           
            await httpsCallable(functions, 'updateEmail')({ email: newEmail });

            const updateEmailToSheet = httpsCallable(functions, 'updateEmailToSheet');
                updateEmailToSheet({ email })
                    .then((result) => {
                        //console.log(result.data);
                    })
                    .catch((error) => {
                        //console.error("Error:", error); 
                    });

            //console.log("Email updated successfully");
            setCurrentEmail('');
            setCurrentPassword('');
            setEmail('');
            setConfirmEmail('');
            
        } catch (error: any) {
            if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found" || error.code === "auth/invalid-login-credentials") {
                setErrorMessage("現在のメールアドレスまたはパスワードが正しくありません。");
            } else {
                setErrorMessage("エラーが発生しました。再度お試しください。");
            }
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={changeStyles.content}>
            <div className={changeStyles.subjectAndClose}>
                <h1 className={changeStyles.subject}>メールアドレスの変更</h1>
                <Link to="/">
                    <IoCloseSharp 
                        size={28} 
                        title="閉じる" 
                        className={changeStyles.closeButton}
                    />
                </Link>
            </div>
            <div className={changeStyles.form}>
                <input 
                    type="email" 
                    value={currentEmail} 
                    onChange={(e) => {setErrorMessage(''); setCurrentEmail(e.target.value)}} 
                    placeholder="現在のメールアドレス"
                />
                <input 
                    type="password" 
                    value={currentPassword} 
                    onChange={(e) => {setErrorMessage(''); setCurrentPassword(e.target.value)}} 
                    placeholder="現在のパスワード"
                />
                <input 
                    type="email" 
                    value={email} 
                    onChange={(e) => {setErrorMessage(''); setEmail(e.target.value)}} 
                    placeholder="新しいメールアドレス"
                />
                <input 
                    type="email" 
                    value={confirmEmail} 
                    onChange={(e) => {setErrorMessage(''); setConfirmEmail(e.target.value)}} 
                    placeholder="新しいメールアドレスの確認"
                />
                {errorMessage && <p className={changeStyles.error}>{errorMessage}</p>}  
                <button onClick={() => handleChangeEmail(email)}>変更</button>
            </div>
        </div>
    );
}

export default EmailChange;
