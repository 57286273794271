import React, { FC, useState } from 'react';
import { auth, functions, db } from '../firebase';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import adminComponentsStyles from './adminComponents_styles.module.scss';

const Upload: FC = () => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    const [files, setFiles] = useState<File[]>([]);
    const [progress, setProgress] = useState<number | null>(null);
    const [uid, setUID] = useState<string>('');
    const [type, setType] = useState<string>('');
    const [service, setService] = useState<string>('');
    const [existUIDs, setExistUIDs] = useState<Set<string>>(new Set());

    const generateRandomString = (length: number): string => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const doesUserExist = async (uid: string): Promise<boolean> => {
        if (existUIDs.has(uid)) return true;

        const userRef = doc(db, "Users", uid);
        const userSnap = await getDoc(userRef);

        const exists = userSnap.exists();
        if (exists) {
            setExistUIDs(prev => new Set(prev).add(uid));
        }

        return exists;
    }

    const imageThumbnailAsBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            canvas.width = 72;
                            canvas.height = 72;
                            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                            resolve(canvas.toDataURL('image/jpeg', 1.0));
                        } else {
                            reject(new Error('Canvas context not available'));
                        }
                    };
                }
            };
            reader.onerror = error => reject(error);
        });
    }
    
    const videoThumbnailAsBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
        
            const video = document.createElement('video');
            video.preload = 'metadata';
    
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                reject(new Error('Canvas context not available'));
                return;
            }
    
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                video.src = reader.result as string;
            };
            reader.onerror = error => {
                reject(error);
            };

            video.onloadedmetadata = () => {
                try {
                    video.currentTime = 0.1; 
                } catch (error) {
                    reject(error);
                }
            };
    
            video.onseeked = () => {
                try {
                    canvas.width = 75;
                    canvas.height = 75; 
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    const thumbnail = canvas.toDataURL('image/jpeg', 1.0);
                    resolve(thumbnail);
                } catch (error) {
                    reject(error);
                }
            };
    
            video.onerror = (event) => {
                reject('Error loading video: ' + event);
            };
        });
    };    

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            setFiles(Array.from(selectedFiles));
        }
    };

    const handleFileUpload = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!files.length) return;
        if (!auth.currentUser) return;

        const userExists = await doesUserExist(uid);
        if (!userExists) {
            console.error("Error: UID does not exist.");
            return;
        }

        files.forEach(async (file) => {
            const fileName = generateRandomString(20);
            const extension = file.name.split('.').pop() || '';
            const path = `Users/${uid}/KirocPosts/${type}/${service}/${fileName}.${extension}`;
            let thumbnail: string;
            if (type === 'image') {
                thumbnail = await imageThumbnailAsBase64(file);
            } else if (type === 'video' && extension === 'mp4') {
                thumbnail = await videoThumbnailAsBase64(file);
            }
    
            const storage = getStorage();
            const storageRef = ref(storage, path);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on('state_changed', 
                (snapshot) => {
                    const currentProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgress(currentProgress);
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }

                }, 
                (error) => {
                    console.error("Upload error:", error.message);
                }, 
                () => {
                    const setKirocFileUrlToFirestore = httpsCallable(functions, 'setKirocFileUrlToFirestore');
                    setKirocFileUrlToFirestore({
                            uid: uid,
                            type: type,
                            service: service,
                            fileName: fileName,
                            path: path,
                            thumbnail: thumbnail,
                        })
                            .then((result) => {
                                setFiles([]);
                                setProgress(null);
                                setUID('');
                                setType('');        
                                setService('');
                                setFiles([]);
                                console.log(result);
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                }
            )
        });
    };

    const getServiceOptions = (): string[] => {
        switch (type) {
            case 'image':
                return ['Quality', 'Color'];
            case 'voice':
                return ['Letters'];
            case 'video':
                return ['Quality', 'Letters'];
            default:
                return [];
        }
    };

    const serviceOptions = getServiceOptions();

    return (
        <div className={adminComponentsStyles.content}>
            <form onSubmit={handleFileUpload} className={adminComponentsStyles.form}>
                <div>
                    <label>UID</label>
                    <input 
                        type="text"
                        value={uid} 
                        onChange={(e) => setUID(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Type</label>
                    <select value={type} onChange={(e) => setType(e.target.value)} required>
                        <option value="">--選択--</option>
                        <option value="image">画像</option>
                        <option value="voice">音声</option>
                        <option value="video">ビデオ</option>
                    </select>
                </div>
                <div>
                    <label>Service</label>
                    <select value={service} onChange={(e) => setService(e.target.value)} required>
                        <option value="">--選択--</option>
                        {serviceOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>ファイル(複数選択可)</label>
                    <input 
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        required 
                    />
                </div>
                <div>
                    <ol>
                        {files.map((file, index) => (
                            <li key={index}>{file.name}</li>
                        ))}
                    </ol>
                </div>
                <div className={adminComponentsStyles.uploadBlock}>
                    <button type="submit">アップロード</button>
                    {progress !== null && <span className={adminComponentsStyles.progress}>{progress}%</span>}
                </div>
            </form>
        </div>
    );
}

export default Upload;