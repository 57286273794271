import { combineReducers } from 'redux';
import navReducer from './navSlice';
import uploadReducer from './uploadSlice';
import fileTypeReducer from './fileTypeSlice';
import cartReducer from './cartSlice';

const rootReducer = combineReducers({
    nav: navReducer,
    upload: uploadReducer,
    fileType: fileTypeReducer,
    cart: cartReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
