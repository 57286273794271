import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import BodyLoading from '../../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import changeStyles from './change_styles.module.scss';

const PasswordChange: FC = () => {

    const [currentEmail, setCurrentEmail] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChangePassword = async (newPassword: string) => {
        if (!currentEmail || !currentPassword || !password || !confirmPassword) {
            setErrorMessage("記入漏れがあります。");
            return;
        }
    
        if (password.length < 6) {
            setErrorMessage("パスワードは6文字以上で入力して下さい。");
            return;
        }
    
        if (password !== confirmPassword) {
            setErrorMessage("入力されたパスワードと確認用のパスワードが一致しません。");
            return;
        }
    
        setIsLoading(true);
    
        if (!auth.currentUser) {
            setIsLoading(false);
            return;
        }
    
        const credential = EmailAuthProvider.credential(currentEmail, currentPassword);
    
        try {
            await reauthenticateWithCredential(auth.currentUser, credential);

            await updatePassword(auth.currentUser, newPassword);

            //console.log("Password updated successfully");
            setCurrentEmail('');
            setCurrentPassword('');
            setPassword('');
            setConfirmPassword('');
            
        } catch (error: any) {
            //console.error(error);
    
            if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found" || error.code === "auth/invalid-login-credentials") {
                setErrorMessage("現在のメールアドレスまたはパスワードが正しくありません。");
            } else {
                setErrorMessage("エラーが発生しました。再度お試しください。");
            }
        } finally {
            setIsLoading(false);
        }
    }    

    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={changeStyles.content}>
            <div className={changeStyles.subjectAndClose}>
                <h1 className={changeStyles.subject}>パスワードの変更</h1>
                <Link to="/">
                    <IoCloseSharp 
                        size={28} 
                        title="閉じる" 
                        className={changeStyles.closeButton}
                    />
                </Link>
            </div>
            <div className={changeStyles.form}>
                <input 
                    type="email" 
                    value={currentEmail} 
                    onChange={(e) => {setErrorMessage(''); setCurrentEmail(e.target.value)}} 
                    placeholder="現在のメールアドレス"
                />
                <input 
                    type="password" 
                    value={currentPassword} 
                    onChange={(e) => {setErrorMessage(''); setCurrentPassword(e.target.value)}} 
                    placeholder="現在のパスワード"
                />
                <input 
                    type="password" 
                    value={password} 
                    onChange={(e) => {setErrorMessage(''); setPassword(e.target.value)}} 
                    placeholder="新しいパスワード"
                />
                <input 
                    type="password" 
                    value={confirmPassword} 
                    onChange={(e) => {setErrorMessage(''); setConfirmPassword(e.target.value)}} 
                    placeholder="新しいパスワードの確認"
                />
                {errorMessage && <p className={changeStyles.error}>{errorMessage}</p>}  
                <button onClick={() => handleChangePassword(password)}>変更</button>
            </div>
        </div>
    );
}

export default PasswordChange;
