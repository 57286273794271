import { createSlice } from '@reduxjs/toolkit';

const uploadSlice = createSlice({
    name: 'upload',
    initialState: {
        uploaded: false,
    },
    reducers: {
        setUploaded: (state, action) => {
            state.uploaded = action.payload;
        },
        resetUploaded: (state) => {
            state.uploaded = false;
        }
    },
});

export const { setUploaded, resetUploaded } = uploadSlice.actions;
export default uploadSlice.reducer;
