import React, { FC, useState } from 'react';
import { functions } from '../firebase';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import adminStyles from './admin_styles.module.scss';
import Login from '../login/login';
import Upload from './upload';
import Register from './register';

const AdminPage: FC = () => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    const [activeTab, setActiveTab] = useState<'upload' | 'register'>('register');
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    type CheckAdminResponse = {
        isAdmin: boolean;
    };

    const checkAdminStatus = async () => {
        try {
            const checkAdmin = httpsCallable(functions, 'checkAdmin');
            const result = await checkAdmin();
            const response = result.data as CheckAdminResponse;
            const isAdmin = response.isAdmin;
            if (isAdmin) {
                setIsAdmin(true);
                console.log("ユーザーは管理者です");
            } else {
                console.log("ユーザーは管理者ではありません");
            }
        } catch (error) {
            console.error("Admin check error:", error);
        }
    };

    if (!isAdmin) return <Login checkAdminStatus={checkAdminStatus} />;

    return (
        <>
            <div className={adminStyles.tab}>
                <button onClick={() => setActiveTab('register')}>新規登録</button>
                <button onClick={() => setActiveTab('upload')}>アップロード</button>
            </div>
            {activeTab === 'register' && <Register />}
            {activeTab === 'upload' && <Upload />}
        </>
    );
}

export default AdminPage;