import { createSlice } from '@reduxjs/toolkit';

const navSlice = createSlice({
    name: 'nav',
    initialState: {
        isNavOpen: false,
    },
    reducers: {
        toggleNav: (state) => {
            state.isNavOpen = !state.isNavOpen;
        },
    },
});

export const { toggleNav } = navSlice.actions;
export default navSlice.reducer;
