import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import BodyLoading from '../../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import changeStyles from './change_styles.module.scss';

const PhoneChange: FC = () => {

    const [currentPhoneNumber, setCurrentPhoneNumber] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [confirmPhoneNumber, setConfirmPhoneNumber] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getPhoneNumber = httpsCallable(functions, 'getPhoneNumber');
        getPhoneNumber()
            .then((result) => {
                const data = (result.data as { phoneNumber: string }).phoneNumber;
                setCurrentPhoneNumber(data);
            })
            .catch((error) => {
                //console.error(error);
            });
    }, []);

    const handleChangePhoneNumber = (newNumber: string) => {
        const phonePattern = /^(070|080|090|050|042)(-\d{4}-\d{4}|\d{7,8})$/
        if (!phonePattern.test(phoneNumber)) {
            setErrorMessage("電話番号の形式が不正です。");
            return;
        }
        if (phoneNumber !== confirmPhoneNumber) {
            setErrorMessage("入力された電話番号と確認用の電話番号が一致しません。");
            return;
        }

        setIsLoading(true);
        
        const updatePhoneNumber = httpsCallable(functions, 'updatePhoneNumber');
        updatePhoneNumber({ phoneNumber: newNumber })
            .then((result) => {
                //console.log(result)

                const updatePhoneNumberToSheet = httpsCallable(functions, 'updatePhoneNumberToSheet');
                updatePhoneNumberToSheet({ phoneNumber: phoneNumber })
                    .then((result) => {
                        //console.log(result.data);
                        setCurrentPhoneNumber(newNumber);
                        setPhoneNumber('');
                        setConfirmPhoneNumber('');
                    })
                    .catch((error) => {
                        //console.error("Error:", error); 
                    });
            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    if (!currentPhoneNumber || isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={changeStyles.content}>
            <div className={changeStyles.subjectAndClose}>
                <h1 className={changeStyles.subject}>電話番号の変更</h1>
                <Link to="/">                
                    <IoCloseSharp 
                        size={28} 
                        title="閉じる" 
                        className={changeStyles.closeButton}
                    />
                </Link>
            </div>
            <div className={changeStyles.form}>
                <div className={changeStyles.block}>
                    <p className={changeStyles.current}>現在の電話番号</p>
                    <p className={changeStyles.currentValue}>{currentPhoneNumber}</p>
                </div>
                <input 
                    type="text" 
                    value={phoneNumber} 
                    onChange={(e) => {setErrorMessage(''); setPhoneNumber(e.target.value)}} 
                    placeholder="新しい電話番号"
                />
                <input 
                    type="text" 
                    value={confirmPhoneNumber} 
                    onChange={(e) => {setErrorMessage(''); setConfirmPhoneNumber(e.target.value)}} 
                    placeholder="新しい電話番号の確認"
                />
                {errorMessage && <p className={changeStyles.error}>{errorMessage}</p>}  
                <button onClick={() => handleChangePhoneNumber(phoneNumber)}>変更</button>
            </div>
        </div>
    );
}

export default PhoneChange;
