import React, { FC, useEffect, useRef, useState } from 'react';
import commonStyles from '../../common/common_styles.module.scss';
import modalStyles from './modal_styles.module.scss';

type ModalProps = {
    downloadURL: string;
    onClose: () => void;
};

type ModalContainerProps = {
    downloadURL: string;
    onClose: () => void;
    children: (ref: React.RefObject<any>) => JSX.Element;
};

const unsupportedMessage = "Your browser does not support this media element.";

const ModalContainer: FC<ModalContainerProps> = ({ children, onClose,}) => {
    const modalRef = useRef<any>(null);

    const handleOutsideClick = (e: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick]);
    
    return (
        <div className={`${commonStyles.bodyOverlapCenter} ${modalStyles.backgroundColor}`}>
            {children(modalRef)}
        </div>
    );
};

const ImageModal: FC<ModalProps> = ({ downloadURL, onClose }) => (
    <ModalContainer downloadURL={downloadURL} onClose={onClose} >
        {(ref) => <img alt="画像" ref={ref} src={downloadURL} className={modalStyles.imageSize} />}
    </ModalContainer>
);

const VoiceModal: FC<ModalProps> = ({ downloadURL, onClose }) => (
    <ModalContainer downloadURL={downloadURL} onClose={onClose} >
        {(ref) => (
            <audio ref={ref} controls>
                <source src={downloadURL}/>
                {unsupportedMessage}
            </audio>
        )}
    </ModalContainer>
);

const VideoModal: FC<ModalProps> = ({ downloadURL, onClose }) => (
    <ModalContainer downloadURL={downloadURL} onClose={onClose} >
        {(ref) => (
            <video ref={ref} className={modalStyles.videoSize} controls>
                <source src={downloadURL} />
                {unsupportedMessage}
            </video>
        )}
    </ModalContainer>
);

export { ImageModal, VoiceModal, VideoModal }



