import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import BodyLoading from '../loading/bodyLoading';
import { BiDownload } from 'react-icons/bi';
import { IoCloseSharp } from 'react-icons/io5';
import { Timestamp } from 'firebase/firestore';
import invoiceStyles from './invoice_styles.module.scss';

const Invoice: FC = () => {
    type FirestoreTimestamp = {
        _seconds: number;
        _nanoseconds: number;
    };

    type Invoice = {
        path: string;
        date: FirestoreTimestamp;
    }    

    type InvoiceData = {
        paths: string[];
        dates: FirestoreTimestamp[];
    }

    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const formatDate = (firestoreTimestamp: FirestoreTimestamp): string => {
        const date = new Timestamp(firestoreTimestamp._seconds, firestoreTimestamp._nanoseconds).toDate();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        
        return `${year}年${month}月${day}日`;
    };

    const getFileNameFromPath = (path: string): string => {
        const segments = path.split('/');
        return segments[segments.length - 1];
    };

    const handlePDFDisplay = async (path: string) => {
        const getInvoiceDownloadURL = httpsCallable(functions, 'getInvoiceDownloadURL');
        try {
            const result = await getInvoiceDownloadURL({ path });
            const downloadURL = (result.data as { downloadURL: string }).downloadURL;
            window.open(downloadURL, '_blank');
        } catch (error) {
            //console.error("Error fetching PDF URL: ", error);
        }
    };

    const handlePDFDownload = async (path: string) => {
        const getInvoiceDownloadURL = httpsCallable(functions, 'getInvoiceDownloadURL');
        try {
            const result = await getInvoiceDownloadURL({ path });
            const downloadURL = (result.data as { downloadURL: string }).downloadURL;
            
            const response = await fetch(downloadURL);
            const blob = await response.blob();
    
            const blobURL = URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = getFileNameFromPath(path);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
    
            URL.revokeObjectURL(blobURL);
        } catch (error) {
            //console.error("Error fetching PDF URL: ", error);
        }
    }

    useEffect(() => {
        const getInvoices = httpsCallable(functions, 'getInvoices');
        getInvoices()
            .then((result) => {
                const { paths, dates } = result.data as InvoiceData;
                const combinedData = dates.map((date, index) => ({ date, path: paths[index] }));
                setInvoices(combinedData);
                setIsLoading(false);
            })
            .catch((error) => {
                //console.error(error);
                setIsLoading(false);
            });
    }, []);

    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={invoiceStyles.content}>
            <div className={invoiceStyles.subjectAndClose}>
                <h1 className={invoiceStyles.subject}>請求書</h1>
                <Link to="/">
                    <IoCloseSharp 
                        size={28} 
                        title="閉じる" 
                        className={invoiceStyles.closeButton}
                    />
                </Link>
            </div>
            <table className={invoiceStyles.block}>
                <tbody>
                    {invoices.map((invoice, index: number) => (
                        <tr key={index}>
                            <td className={invoiceStyles.date}>{formatDate(invoice.date)}</td>
                            <td>
                                <button
                                    type="button"
                                    className={invoiceStyles.display}
                                    onClick={() => handlePDFDisplay(invoice.path)}
                                >
                                    {getFileNameFromPath(invoice.path)}
                                </button>
                            </td>
                            <td>
                                <button
                                    type="button"
                                    onClick={(e) => { e.preventDefault(); handlePDFDownload(invoice.path); }}
                                >
                                    <BiDownload
                                        size={22} 
                                        title="download"
                                        className={invoiceStyles.icon} 
                                    />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Invoice;
