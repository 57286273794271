import React, { FC } from 'react';
import { TabType, PostType, ServiceType, tabs } from '../constants/constants';
import UploadButton from './uploadButton';
import typeButtonsStyles from './typeButtons_styles.module.scss';

type TypeButtonsProps = {
    activeTab: TabType;
    activeType: PostType;
    activeService: ServiceType;
    setActiveType: (type: PostType) => void;
};

const TypeButtons: FC<TypeButtonsProps> = ({ activeTab, activeType, activeService, setActiveType }) => {

    return (
        <div className={typeButtonsStyles.typeWrapper}>
            <div>
                {(Object.entries(tabs[activeTab].types) as [PostType, string][]).map(([key, label]) => (
                    <button 
                        key={key} 
                        className={key === activeType ? typeButtonsStyles.active : ''} 
                        onClick={() => setActiveType(key)}
                    >
                        {label}
                    </button>
                ))}
            </div>
            {activeTab === 'UserPosts' && 
                <UploadButton activeService={activeService} />
            }
        </div>
    );
   
}
  
export default TypeButtons;