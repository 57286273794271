import React, { FC, useState, useEffect } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { auth, functions } from '../../firebase';
import { connectFunctionsEmulator, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import uploadButtonStyles from './uploadButton_styles.module.scss';
import { PostType, ServiceType } from '../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setUploaded } from '../../common/reducers/uploadSlice';
import { setFileType } from '../../common/reducers/fileTypeSlice';
import { RootState } from '../../common/reducers';

type UploadButtonProps = {
    activeService: ServiceType;
};

const UploadButton: FC<UploadButtonProps> = ({ activeService }) => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    const dispatch = useDispatch();
    const [file, setFile] = useState<File | null>(null);
    const fileType = useSelector((state: RootState) => state.fileType);
    const [progress, setProgress] = useState<number | null>(null);

    const generateRandomString = (length: number): string => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    }

    const imageThumbnailAsBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const image = new Image();
                    image.src = reader.result;
                    image.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            canvas.width = 72;
                            canvas.height = 72;
                            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                            resolve(canvas.toDataURL('image/jpeg', 1.0));
                        } else {
                            reject(new Error('Canvas context not available'));
                        }
                    };
                }
            };
            reader.onerror = error => reject(error);
        });
    }

    const videoThumbnailAsBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const video = document.createElement('video');

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                video.src = reader.result as string;
            };
            reader.onerror = error => reject(error);
    
            video.onloadeddata = () => {
                video.currentTime = 0;
            };
    
            video.onseeked = () => {
                if (ctx) {
                    canvas.width = 75;
                    canvas.height = 75;
                    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                    resolve(canvas.toDataURL('image/jpeg', 1.0));
                } else {
                    reject(new Error('Canvas context not available'));
                }
            };
        });
    }
    
    const determineFileType = (filename: string): PostType => {
        const extension = filename.split('.').pop()?.toLowerCase();
    
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
        const voiceExtensions = ['mp3', 'wav', 'ogg', 'm4a'];
        const videoExtensions = ['mp4', 'mkv', 'flv', 'avi', 'mov'];
    
        if (imageExtensions.includes(extension || '')) {
            return 'image';
        } else if (voiceExtensions.includes(extension || '')) {
            return 'voice';
        } else if (videoExtensions.includes(extension || '')) {
            return 'video';
        } else {
            throw new Error("Unsupported file type");
        }
    };

    useEffect(() => {
        if (file) {
            handleFileUpload();
        }
    }, [file]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const type = determineFileType(files[0].name);
            dispatch(setFileType(type)); 
            setFile(files[0]);
        }
        e.target.value = '';
    };

    const handleFileUpload = async () => {
        if (!file) return;
        if (!auth.currentUser) return;

        const uid = auth.currentUser.uid;
        const type = fileType;
        const service = activeService;
        const fileName = generateRandomString(20);
        const extension = file.name.split('.').pop() || '';
        const path = `Users/${uid}/UserPosts/${type}/${service}/${fileName}.${extension}`;
    
        const storage = getStorage();
        const storageRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(storageRef, file);
        let thumbnail: string;
        if (fileType === 'image') {
            thumbnail = await imageThumbnailAsBase64(file);
        } else if (fileType === 'video' && extension === 'mp4') {
            thumbnail = await videoThumbnailAsBase64(file);
        }
        //console.log(type);

        uploadTask.on('state_changed', 
            (snapshot) => {
                const currentProgress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgress(currentProgress);
                //console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        //console.log('Upload is paused');
                        break;
                    case 'running':
                        //console.log('Upload is running');
                        break;
                }

            }, 
            (error) => {
                //console.error("Upload error:", error.message);
            }, 
            () => {
                //console.log('videoTHum')
                const setUserFileUrlToFirestore = httpsCallable(functions, 'setUserFileUrlToFirestore');
                    setUserFileUrlToFirestore({
                        type: type,
                        service: service,
                        fileName: fileName,
                        path: path,
                        thumbnail: thumbnail,
                    })
                        .then((result) => {
                            dispatch(setUploaded(true));
                            setFile(null);
                            setProgress(null);
                            //console.log(result);
                        })
                        .catch((error) => {
                            //console.error(error);
                        });
            }
        );
    };

    return (
        <div className={uploadButtonStyles.uploadBlock}>
            <label>
                <AiOutlineCloudUpload size={30} title="アップロード" className={uploadButtonStyles.uploadIcon}/>
                <input 
                    type="file"
                    className={uploadButtonStyles.hiddenInput} 
                    onChange={handleFileChange} 
                />
            </label>
            {progress !== null && <span className={uploadButtonStyles.progress}>{progress}%</span>}
        </div>
    );
}

export default UploadButton;
