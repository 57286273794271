import React, { FC, useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import commonStyles from '../common/common_styles.module.scss';
import loginStyles from './login_styles.module.scss';

type LoginProps = {
    onLogin?: () => void;
    checkAdminStatus?: () => void;
};

const Login: FC<LoginProps> = ({ onLogin, checkAdminStatus }) => {
    const [resetMessage, setResetMessage] = useState<string>('');
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            onLogin?.();
            checkAdminStatus?.();
        } catch (error) {
            setLoginErrorMessage('メールアドレスまたはパスワードが違います。');
        }
    };

    const sendPasswordReset = () => {
        if (!email) {
            setLoginErrorMessage('メールアドレスを入力して下さい。');
            return;
        }

        sendPasswordResetEmail(auth, email)
            .then(() => {
                setLoginErrorMessage('');
                setResetMessage('パスワード再設定メールを送信しました。認証後、ログインが可能になります。');
            })
            .catch ((error: any) => {
                setResetMessage('');
                if (error.code === 'auth/invalid-email') {
                    setLoginErrorMessage("無効なメールアドレスです。");
                } else if (error.code === 'auth/user-not-found') {
                    setLoginErrorMessage("このメールアドレスのユーザーは存在しません。");
                } else {
                    setLoginErrorMessage("エラーが発生しました。再度お試しください。");
                    //console.error(error);
                }
            })
    }

    return (
        <div className={`${commonStyles.bodyOverlapCenter}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <div className={loginStyles.loginForm}>
                <input type="email" value={email} onChange={(e) => {setLoginErrorMessage(''); setEmail(e.target.value)}} placeholder="メールアドレス"/>
                <input type="password" value={password} onChange={(e) => {setLoginErrorMessage(''); setResetMessage(''); setPassword(e.target.value)}} placeholder="パスワード"/>
                {loginErrorMessage && <p className={loginStyles.error}>{loginErrorMessage}</p>}
                {resetMessage && <p className={loginStyles.reset}>{resetMessage}</p>}
                <div className={loginStyles.block}>  
                    <button onClick={handleLogin}>ログイン</button>
                    <span onClick={sendPasswordReset}>パスワードを忘れた方</span>
                </div>
            </div>
        </div>
    );
}

export default Login;
