import React, { FC, useState } from 'react';
import { auth, functions, db } from '../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import { addDoc, setDoc, doc, collection, serverTimestamp } from "firebase/firestore";
import adminComponentsStyles from './adminComponents_styles.module.scss';
import { httpsCallable } from 'firebase/functions';

const Register: FC = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [name, setName] = useState('');
    const [katakanaName, setKatakanaName] = useState('');
    const [age, setAge] = useState<number | null>(null);
    const [gender, setGender] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [plan, setPlan] = useState('');

    const createSignup = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            console.error("Passwords do not match");
            return;
        }

        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log("User created:", userCredential.user);
                const uid = userCredential.user.uid;

                setDoc(doc(db, "Users", uid), {
                    createdAt: serverTimestamp()
                });        

                addDoc(collection(db, `Users/${uid}/Profile`), {
                    name,
                    katakanaName,
                    age,
                    gender,
                    email,
                    phone,
                    address
                })
                
                addDoc(collection(db, `Users/${uid}/Contract`), {
                    plan,
                })
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setName('');
                setKatakanaName('');
                setAge(null);
                setGender('');
                setPhone('');
                setAddress('');
                setPlan('');
                console.log('added to firestore');

                const addUserToSheet = httpsCallable(functions, 'addUserToSheet');
                addUserToSheet({
                    uid,
                    phone,
                    email,
                    name,
                    katakanaName,
                    age,
                    address
                })
                    .then((result) => {
                        console.log("added to Google Sheets:", result.data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });

            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        <div className={adminComponentsStyles.content}>
            <form onSubmit={createSignup} className={adminComponentsStyles.form}>
                <div>
                    <label>メールアドレス</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>仮パスワード</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>確認用仮パスワード</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>名前(漢字)</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>名前(カタカナ)</label>
                    <input
                        type="text"
                        value={katakanaName}
                        onChange={(e) => setKatakanaName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>年齢</label>
                    <input
                        type="number"
                        value={age || ''}
                        onChange={(e) => setAge(Number(e.target.value))}
                        required
                    />
                </div>
                <div>
                    <label>性別</label>
                    <select value={gender} onChange={(e) => setGender(e.target.value)} required>
                        <option value="">-- 選択 --</option>
                        <option value="男性">男性</option>
                        <option value="女性">女性</option>
                        <option value="その他">その他</option>
                    </select>
                </div>
                <div>
                    <label>電話番号</label>
                    <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>住所</label>
                    <input
                        type="text"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>初期プラン</label>
                    <select value={plan} onChange={(e) => setPlan(e.target.value)} required>
                        <option value="">--選択--</option>
                        <option value="保管プラン">保管プラン</option>
                        <option value="再現プラン">再現プラン</option>
                    </select>
                </div>
                <button type="submit">新規登録</button>
            </form>
        </div>
    );
}

export default Register;