import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase'
import { signOut } from "firebase/auth";
import { useDispatch, useSelector} from 'react-redux';
import { RootState } from '../common/reducers';
import { toggleNav } from '../common/reducers/navSlice';
import navigationStyles from './navigation_styles.module.scss';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiFillHome, AiOutlinePhone } from 'react-icons/ai'
import { BiLogOut } from 'react-icons/bi';
import { FaRegHandshake } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { HiDocumentText, HiOutlineIdentification } from 'react-icons/hi';
import { IoCloseSharp } from 'react-icons/io5';
import { RiLockPasswordFill } from 'react-icons/ri';
import { SlPresent } from 'react-icons/sl';

const Navigation: FC = () => {
    const dispatch = useDispatch();
    const isNavOpen = useSelector((state: RootState) => state.nav.isNavOpen);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                //console.log("Successfully logged out!");
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    return (
        <>
            <button className={navigationStyles.navButton} onClick={() => dispatch(toggleNav())}>
                {isNavOpen ? <IoCloseSharp size={28} title="閉じる"/> : <GiHamburgerMenu size={28} title="メニューバー"/>}
            </button>
            {isNavOpen && (
                <nav>
                    <div className={navigationStyles.content}>
                        <div className={navigationStyles.block}>
                            <h1>登録情報</h1>
                            <ul>
                                <Link to="userInfo">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <HiOutlineIdentification size={20} title="メンバー" className={navigationStyles.icon}/>会員情報
                                    </li>
                                </Link>
                                <Link to="phoneChange">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <AiOutlinePhone size={20} title="電話番号" className={navigationStyles.icon}/>電話番号の変更
                                    </li>
                                </Link>
                                <Link to="emailChange">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <GrMail size={20} title="メールアドレス" className={navigationStyles.icon}/>メールアドレスの変更
                                    </li>
                                </Link>
                                <Link to="passwordChange">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <RiLockPasswordFill size={20} title="パスワード" className={navigationStyles.icon}/>パスワードの変更
                                    </li>
                                </Link>
                                <Link to="addressChange">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <AiFillHome size={20} title="住所" className={navigationStyles.icon}/>住所の変更
                                    </li>
                                </Link>
                            </ul>
                        </div>
                        <div className={navigationStyles.block}>
                            <h1>購入</h1>
                            <ul>
                                <Link to="contractChange">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <FaRegHandshake size={20} title="契約プラン" className={navigationStyles.icon}/>契約プラン
                                    </li>
                                </Link>
                                <Link to="letters">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <SlPresent size={20} title="音声・ビデオの再現" className={navigationStyles.icon}/>音声・ビデオの再現
                                    </li>
                                </Link>
                            </ul>
                        </div>
                        <div className={navigationStyles.block}>
                            <h1>請求情報</h1>
                            <ul>
                                <Link to="invoice">
                                    <li onClick={() => {dispatch(toggleNav())}}>
                                        <HiDocumentText size={20} title="請求書" className={navigationStyles.icon}/>請求書の確認
                                    </li>
                                </Link>
                            </ul>
                        </div>
                        <div className={navigationStyles.block}>
                            <h1>ログアウト</h1>
                            <ul>
                                <li onClick={() => {
                                    handleLogout();
                                    dispatch(toggleNav());
                                }}>
                                    <BiLogOut size={20} title="ログアウト" className={navigationStyles.icon}/>ログアウト
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            )}
        </>
    )
}

export default Navigation;
