import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth, functions } from '../../firebase';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
/*import { Product, addToCart } from '../../common/reducers/cartSlice';
import { useDispatch } from 'react-redux';*/
import BodyLoading from '../../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import { BsArrowReturnRight } from 'react-icons/bs';
import changeStyles from './change_styles.module.scss';
import commonStyles from '../../common/common_styles.module.scss';
import loginStyles from '../../login/login_styles.module.scss';

const ContractChange: FC = () => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [isMessage, setIsMessage] = useState<string>('');
    const [currentPlan, setCurrentPlan] = useState<string>('');
    const [selectedPlan, setSelectedPlan] = useState<string>('');

    useEffect(() => {
        const getPlan= httpsCallable(functions, 'getPlan');
        getPlan()
            .then((result) => {
                const data = (result.data as { plan: string }).plan;
                setCurrentPlan(data);
            })
            .catch((error) => {
                //console.error(error);
            });
    }, []);

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const formattedDate = `${year}年${month}月${day}日`;

    const getProductId = (plan: string) => {
        switch(plan) {
            case '保管プラン':
                return 2;
            case '再現プラン':
                return 3;
            default:
                return 0;
        }
    }

    const handleApply = async () => {
        setIsLoading(true);
        const user = auth.currentUser;
        if (user === null) return;

        const items = [
            {
                id: getProductId(selectedPlan),
                name: selectedPlan,
                quantity: 1,
            }
        ];

        const applyData = {
            date: formattedDate,
            email: user.email,
            items: items,
        };

        try {
            const applyToMail = httpsCallable(functions, 'applyToMail')
            await applyToMail({ applyData });
            setIsMessage('申し込みを受け付けました。3日後までにご連絡させていただきます。');
        } catch (error) {
            //console.error("Error creating applyToMail: ", error);
            setIsMessage('申し込みに失敗しました。再度お試しください。');
        }
        setSelectedPlan('');
        setIsFinished(true);
        setIsLoading(false);
    }

    const availablePlans = currentPlan === '保管プラン' ? ['再現プラン'] : [];

    if ((!currentPlan) || isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={changeStyles.planContent}>
            {isFinished && (
                <div className={`${commonStyles.bodyOverlapCenter}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    <div className={loginStyles.loginForm}>
                        <p>{isMessage}</p>
                        <button onClick={() => {setIsFinished(false)}}>閉じる</button>
                    </div>
                </div>
            )}
            <div className={changeStyles.subjectAndClose}>
                <h1 className={changeStyles.subject}>契約プラン</h1>
                <Link to="/">
                    <IoCloseSharp 
                        size={28} 
                        title="閉じる" 
                        className={changeStyles.closeButton}
                    />
                </Link>
            </div>
            <div className={changeStyles.block}>
                <p className={changeStyles.current}>現在のプラン</p>
                <p className={changeStyles.currentValue}>{currentPlan}</p>
            </div>
            {currentPlan === '保管プラン' && (
                <div className={changeStyles.changeBlock}>
                    <BsArrowReturnRight size={16} title='矢印' />
                    <a href="#planChangeSection"><span>アップグレードはこちら</span></a>
                </div>
            )}
            <table className={changeStyles.planTable}>
                <thead>
                    <tr>
                        <th colSpan={2}>プラン</th>
                        <th className={changeStyles.vertical}>保管</th>
                        <th>再現</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan={2}>初期費用</td>
                        <td className={changeStyles.vertical}>無料</td>
                        <td>118,600円<span> + 収集・スキャン費用</span></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>データの収集</td>
                        <td>○</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>データのスキャン</td>
                        <td>○</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>データの保管</td>
                        <td>○</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>データの供養</td>
                        <td>○</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td>写真</td>
                        <td>高画質化・カラー化</td>
                        <td>○</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td>音声</td>
                        <td>AIが創る故人の声</td>
                        <td>×</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td rowSpan={3} className={changeStyles.vertical}>ビデオ</td>
                        <td>写真のビデオ化</td>
                        <td>×</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td>AIが創る故人の姿</td>
                        <td>×</td>
                        <td>○</td>
                    </tr>
                    <tr>
                        <td>通話</td>
                        <td>×</td>
                        <td>○</td>
                    </tr>
                </tbody>
            </table>
            {currentPlan === '保管プラン' && (
                <div className={changeStyles.block} id="planChangeSection">
                    <p className={changeStyles.current}>アップグレード</p>
                    <select
                        className={changeStyles.currentValue}
                        value={selectedPlan}
                        onChange={(e) => setSelectedPlan(e.target.value)}
                        style={{ cursor: "pointer" }}
                    >
                        <option value="">選択</option>
                        {availablePlans.map(plan => (
                            <option key={plan} value={plan}>{plan}</option>
                        ))}
                    </select>
                </div>
            )}
            {selectedPlan && (
                <div className={changeStyles.apply}>
                    <button className={changeStyles.cartButton} onClick={handleApply}>
                        申し込む
                    </button>
                    <p>※ 申し込みをしても注文は確定しません。料金等は後日ご連絡させていただきます。</p>
                </div>
            )}
        </div>
    );
}

export default ContractChange;