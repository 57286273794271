import React, { FC } from 'react';
import { TabType, PostType, ServiceType, tabs } from '../constants/constants';
import tabButtonsStyles from './tabButtons_styles.module.scss';

type TabButtonsProps = {
    activeTab: TabType;
    setActiveTab: (tab: TabType) => void;
    setActiveType: (type: PostType) => void;
    setActiveService: (service: ServiceType) => void;
};

const TabButtons: FC<TabButtonsProps> = ({ activeTab, setActiveTab, setActiveType, setActiveService }) => {
    return (
        <div className={tabButtonsStyles.tabWrapper}>
            {(Object.keys(tabs) as TabType[]).map(key => (
                <button 
                    key={key} 
                    className={key === activeTab ? tabButtonsStyles.active : ''}
                    onClick={() => {
                        setActiveTab(key);
                        setActiveType('image');  
                        if (key === 'UserPosts') {
                            setActiveService('None');
                        }
                    }}
                >
                    {tabs[key].name}
                </button>
            ))}
        </div>
    );
}

export default TabButtons;