import React, { FC, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth";
import { useSelector } from 'react-redux';
import { RootState } from '../common/reducers';
import { TabType, PostType, ServiceType } from './constants/constants';
import TabButtons from './components/tabButtons';
import TypeButtons from './components/typeButtons';
import ServiceButtons from './components/serviceButtons';
import Display from './components/display';
import Sidebar from '../sidebar/sidebar';
import commonStyles from '../common/common_styles.module.scss';
import userPageStyles from './userPage_styles.module.scss';
import BodyLoading from '../loading/bodyLoading';
import Login from '../login/login';
import UserInfo from './userInfo';
import PhoneChange from './changes/phoneChange';
import PasswordChange from './changes/passwordChange';
import EmailChange from './changes/emailChange';
import AddressChange from './changes/addressChange';
import ContractChange from './changes/contractChange';
import Letters from '../cart/letters';
import Cart from '../cart/cart';
import Invoice from '../invoice/invoice';

const UserPage: FC = () => {
    const [activeTab, setActiveTab] = useState<TabType>('KirocPosts');
    const [activeType, setActiveType] = useState<PostType>('image');
    const [activeService, setActiveService] = useState<ServiceType>('Quality');
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const isNavOpen = useSelector((state: RootState) => state.nav.isNavOpen);
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLogin(true);
            } else {
                setIsLogin(false);
            }
            setIsLoading(false); 
        });

        return () => unsubscribe();
    }, []);

    const handleOnLogin = () => setIsLogin(true);

    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    if (!isLogin) return <Login onLogin={handleOnLogin} />;

    return (
        <div className={commonStyles.wrapper}>
        	<Sidebar />
          	<main>
                {!isNavOpen && (
                    <Routes>
                        <Route path="/cart" element={<Cart />} />
                        <Route path="/userInfo" element={<UserInfo />} />
                        <Route path="/phoneChange" element={<PhoneChange />} />
                        <Route path="/emailChange" element={<EmailChange />} />
                        <Route path="/passwordChange" element={<PasswordChange />} />
                        <Route path="/addressChange" element={<AddressChange />} />
                        <Route path="/contractChange" element={<ContractChange />} />
                        <Route path="/letters" element={<Letters />} />
                        <Route path="/invoice" element={<Invoice />} />
                        <Route path="/" element={
                            <div>
                                <section className={userPageStyles.buttonsWrapper}>
                                    <TabButtons
                                        activeTab={activeTab} 
                                        setActiveTab={setActiveTab} 
                                        setActiveType={setActiveType} 
                                        setActiveService={setActiveService} 
                                    />
                                    <TypeButtons
                                        activeTab={activeTab} 
                                        activeType={activeType} 
                                        activeService={activeService} 
                                        setActiveType={setActiveType} 
                                    />
                                </section>
                                <section className={userPageStyles.content}>
                                    {activeTab === 'KirocPosts' && (
                                        <ServiceButtons 
                                            activeType={activeType} 
                                            activeService={activeService} 
                                            setActiveService={setActiveService} 
                                        />
                                    )}
                                    <Display 
                                        activeTab={activeTab} 
                                        activeType={activeType} 
                                        activeService={activeService} 
                                    />
                                </section>
                            </div>
                        } />
                    </Routes>
                )}
            </main>
        </div>
    );
}

export default UserPage;
