import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import sidebarStyles from './sidebar_styles.module.scss';
import { auth } from '../firebase'
import { signOut } from "firebase/auth";
import { AiFillHome, AiOutlinePhone } from 'react-icons/ai';
import { BiLogOut, BiRevision } from 'react-icons/bi';
import { FaRegHandshake } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { HiDocumentText, HiOutlineIdentification } from 'react-icons/hi';
import { RiLockPasswordFill } from 'react-icons/ri';

const Sidebar: FC = () => {
    const location = useLocation();

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                //console.log("Successfully logged out!");
            })
            .catch((error) => {
                //console.error(error);
            });
    }

    const getLinkPath = (basePath: string) => {
        const currentPath = location.pathname;
        const isAtBasePath = currentPath.endsWith(basePath) || currentPath.endsWith(`${basePath}/`);
        return isAtBasePath ? '/' : `/${basePath}`;
    };

    return (
        <aside>
            <div className={sidebarStyles.content}>
                <div className={sidebarStyles.block}>
                    <h1>登録情報</h1>
                    <ul>
                        <li>
                            <Link to={getLinkPath('userInfo')}>
                                <HiOutlineIdentification size={20} title="メンバー" className={sidebarStyles.icon}/>
                            </Link>
                            <Link to={getLinkPath('userInfo')}><span>会員情報</span></Link>
                        </li>
                        <li>
                            <Link to={getLinkPath('phoneChange')}>
                                <AiOutlinePhone size={20} title="電話番号" className={sidebarStyles.icon} />
                            </Link>
                            <Link to={getLinkPath('phoneChange')}><span>電話番号の変更</span></Link>
                        </li>
                        <li>
                            <Link to={getLinkPath('emailChange')}>
                                <GrMail size={20} title="メールアドレス" className={sidebarStyles.icon} />
                            </Link>
                            <Link to={getLinkPath('emailChange')}><span>メールアドレスの変更</span></Link>
                        </li>
                        <li>
                            <Link to={getLinkPath('passwordChange')}>
                                <RiLockPasswordFill size={20} title="パスワード" className={sidebarStyles.icon} />
                            </Link>
                            <Link to={getLinkPath('passwordChange')}><span>パスワードの変更</span></Link>
                        </li>
                        <li>
                            <Link to={getLinkPath('addressChange')}>
                                <AiFillHome size={20} title="住所" className={sidebarStyles.icon} />
                            </Link>
                            <Link to={getLinkPath('addressChange')}><span>住所の変更</span></Link>
                        </li>
                    </ul>
                </div>
                <div className={sidebarStyles.block}>
                    <h1>購入</h1>
                    <ul>
                        <li>
                            <Link to={getLinkPath('contractChange')}>
                                <FaRegHandshake size={20} title="契約プラン" className={sidebarStyles.icon} />
                            </Link>
                            <Link to={getLinkPath('contractChange')}><span>契約プラン</span></Link>
                        </li>
                        <li>
                            <Link to={getLinkPath('letters')}>
                                <BiRevision size={20} title="音声・ビデオの再現" className={sidebarStyles.icon} />
                            </Link>
                            <Link to={getLinkPath('letters')}><span>音声・ビデオの再現</span></Link>
                        </li>
                    </ul>
                </div>
                <div className={sidebarStyles.block}>
                    <h1>請求情報</h1>
                    <ul>
                        <li>
                            <Link to={getLinkPath('invoice')}>
                                <HiDocumentText size={20} title="請求書" className={sidebarStyles.icon}/>
                            </Link>
                            <Link to={getLinkPath('invoice')}><span>請求書の確認</span></Link>
                        </li>
                    </ul>
                </div>
                <div className={sidebarStyles.block}>
                    <h1>ログアウト</h1>
                    <ul>
                        <li onClick={handleLogout}>
                            <BiLogOut size={20} title="ログアウト" className={sidebarStyles.icon}/>
                            <span>ログアウト</span>
                        </li>                    
                    </ul>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar;
