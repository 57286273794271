import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, functions } from '../firebase';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { useSelector } from 'react-redux';
import { RootState } from '../common/reducers';
import { useDispatch } from 'react-redux';
import { clearCart, removeFromCart, changeItemQuantity } from '../common/reducers/cartSlice';
import { IoCloseSharp } from 'react-icons/io5';
import BodyLoading from '../loading/bodyLoading';
import changeStyles from '../user/changes/change_styles.module.scss';

const Cart: FC = () => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const cart = useSelector((state: RootState) => state.cart.items);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRemoveFromCart = (id: number, path?: string) => {
        dispatch(removeFromCart({ id, path }));
        if (sortedCart.length === 1) {
            navigate('/');
        }
    };

    const getPrice = (id: number) => {
        switch (id) {
            case 1: return 5800;
            case 2: return 0;
            case 3: return 52800;
            case 4: return 98600;
            case 5: return 420;
            case 6: return 420;
            case 7: return 4800;
            case 8: return 3500;
            case 9: return 9600;
            case 10: return 3500;
            default: return 0;
        }
    };

    const sortedCart = [...cart].sort((a, b) => a.id - b.id);

    const total = sortedCart.reduce((acc, item) => {
        const itemTotal = item.quantity * getPrice(item.id);
        return acc + itemTotal;
    }, 0);

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const formattedDate = `${year}年${month}月${day}日`;

    const extractFileName = (path: String) => {
        const parts = path.split('/');
        const fullFileName = parts[parts.length - 1];
        return fullFileName.split('.')[0];
    }

    const extractFileNameWithExtension = (path: string) => {
        const parts = path.split('/');
        return parts[parts.length - 1];
    }

    const handleQuantityChange = (id: number, newQuantity: number) => {
        if (!newQuantity) {
            newQuantity = 1;
        }

        if (newQuantity > 0) {
            dispatch(changeItemQuantity({ id, quantity: newQuantity }));
        }
    };

    const handlePurchase = async () => {
        setIsLoading(true);
        const user = auth.currentUser;
        if (user === null) return;

        const invoiceData = {
            date: formattedDate,
            email: user.email,
            items: sortedCart.map((item) => ({
                id: item.id,
                name: item.name,
                quantity: item.quantity,
                price: getPrice(item.id),
                ...(item.paths ? { paths: item.paths } : {}),
                fileNames: item.paths ? item.paths.map(path => extractFileNameWithExtension(path)) : [],
            })),
        };

        const itemsToUpdate = [];
        for (const item of invoiceData.items) {
            if (item.paths) {
                for (const path of item.paths) {
                    const fileName = extractFileName(path);
                    itemsToUpdate.push({ id: item.id, fileName });
                }
            }
        }

        const eligibleItemIds = [2, 3, 4];
        let targetItem = null;
        for (const item of invoiceData.items) {
            if (eligibleItemIds.includes(item.id)) {
                targetItem = item;
                break;
            }
        }
    
        try {
            const createInvoice = httpsCallable(functions, 'createInvoice')
            await createInvoice({ invoiceData });

            const updateAttributes = httpsCallable(functions, 'updateAttributes')
            await updateAttributes({ itemsToUpdate });

            if (targetItem) {
                const updatePlan = httpsCallable(functions, 'updatePlan');
                await updatePlan({ item: targetItem });
            }

            dispatch(clearCart());
            navigate('/invoice');
        } catch (error) {
            //console.error("Error creating invoice or updating attrubutes: ", error);
        }
        setIsLoading(false);
    };
    
    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={changeStyles.planContent}>
            <div className={changeStyles.subjectAndClose}>
                <h1 className={changeStyles.subject}>請求書</h1>
                <Link to="/">
                    <IoCloseSharp 
                        size={28}
                        title="閉じる" 
                        className={changeStyles.closeButton}
                    />
                </Link>
            </div>
            <p>{year}年{month}月{day}日</p>
            <table className={changeStyles.invoiceTable}>
                <thead>
                    <tr>
                        <th>商品</th>
                        <th>数量</th>
                        <th>単価</th>
                        <th>小計</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedCart.map((item, index) => {
                        const price = getPrice(item.id);
                        const subtotal = item.quantity * price;
                        const uniqueKey = `${item.id}-${index}`;

                        return (
                            <tr key={uniqueKey}>
                                <td>
                                    <div className={changeStyles.productRow}>
                                    <div className={changeStyles.nameContainer}>
                                        <div>{item.name}</div>
                                        {item.paths && item.paths.map(path => (
                                            <div key={path} style={{ fontSize: 'small' }}>{extractFileNameWithExtension(path)}</div>
                                        ))}
                                    </div>                                
                                    {item.thumbnail && (
                                        <img
                                            src={item.thumbnail}
                                            alt="thumbnail"
                                            className={changeStyles.thumbnailImage}
                                        />
                                    )}
                                    </div>
                                </td>
                                {[7, 9, 10].includes(item.id) ? (
                                    <td>
                                        <input
                                            type="number"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(item.id, parseInt(e.target.value))}
                                            min="1"
                                        />
                                    </td>
                                ) : (
                                    <td>{item.quantity}</td>
                                )}
                                <td>{price.toLocaleString()}円</td>
                                <td>{subtotal.toLocaleString()}円</td>
                                <td>
                                    <button style={{ color: 'blue'}} onClick={() => handleRemoveFromCart(item.id, item.paths ? item.paths[0] : undefined)}>
                                        削除
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td colSpan={4} style={{ fontWeight: 'bold' }}>合計</td>
                        <td style={{ fontWeight: 'bold' }}>{total.toLocaleString()}円</td>
                    </tr>
                </tbody>
            </table>
            <button className={changeStyles.buyButton} onClick={handlePurchase}>購入</button>
        </div>
    );
}

export default Cart;