import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import BodyLoading from '../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import userInfoStyles from './userInfo_styles.module.scss';

type UserData = {
    name: string;
    katakanaName: string;
    age: number;
    gender: string;
    email: string;
    phone: string;
    address: string;
}

const UserInfo: FC = () => {
    const [userData, setUserData] = useState<UserData | null>(null);

    useEffect(() => {
        const getUserProfile = httpsCallable(functions, 'getUserProfile');
        getUserProfile()
            .then((result) => {
                const data = (result.data as { profileData: UserData }).profileData;
                setUserData(data);
            })
            .catch((error) => {
                //console.error(error);
            });
    }, []);

    if (!userData) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={userInfoStyles.content}>
            <div className={userInfoStyles.subjectAndClose}>
                <h1 className={userInfoStyles.subject}>会員情報</h1>
                <Link to="/">
                    <IoCloseSharp
                        size={28} 
                        title="閉じる" 
                        className={userInfoStyles.closeButton}
                    />
                </Link>
            </div>
            <div className={userInfoStyles.block}>
                <div className={userInfoStyles.item}>
                    <span className={userInfoStyles.label}>氏名</span>
                    <div>
                        <span className={userInfoStyles.katakana}>{userData.katakanaName}</span>
                        <span className={userInfoStyles.value}>{userData.name}</span>
                    </div>
                </div>
                <div className={userInfoStyles.item}>
                    <span className={userInfoStyles.label}>年齢</span>
                    <span className={userInfoStyles.value}>{userData.age}</span>
                </div>
                <div className={userInfoStyles.item}>
                    <span className={userInfoStyles.label}>性別</span>
                    <span className={userInfoStyles.value}>{userData.gender}</span>
                </div>
                <div className={userInfoStyles.item}>
                    <span className={userInfoStyles.label}>メールアドレス</span>
                    <span className={userInfoStyles.value}>{userData.email}</span>
                </div>
                <div className={userInfoStyles.item}>
                    <span className={userInfoStyles.label}>電話番号</span>
                    <span className={userInfoStyles.value}>{userData.phone}</span>
                </div>
                <div className={userInfoStyles.item}>
                    <span className={userInfoStyles.label}>住所</span>
                    <span className={userInfoStyles.value}>{userData.address}</span>
                </div>
            </div>
        </div>
    );
}

export default UserInfo;
