import React, { FC } from 'react';
import commonStyles from '../common/common_styles.module.scss';
import { FadeLoader } from 'react-spinners';

type BodyLoadingProps = {
    backgroundColor: string;
    paddingTop: string;
}

const BodyLoading: FC<BodyLoadingProps> = ({ backgroundColor, paddingTop }) => {
    return (
        <div className={commonStyles.bodyOverlapTop} style={{ backgroundColor: backgroundColor, paddingTop: paddingTop }}>
            <FadeLoader color="#4b2139" />
        </div>
    );
}

export default BodyLoading;
