import React, {FC} from 'react';
//import Test from './test/test';
import Header from './header/header';
import AdminPage from './admin/adminPage'
import UserPage from './user/userPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './common/common_styles.module.scss';

const App: FC = () => {
    return (
        <>
            <Router>  
                <Header/>
                <Routes>
                    <Route path="/adminPage" element={<AdminPage />} />
                    <Route path="/*" element={<UserPage />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;
