import React, { FC, useEffect } from 'react';
import { PostType, ServiceType, services } from '../constants/constants';
import serviceButtonsStyles from './serviceButtons_styles.module.scss';

type ServiceButtonsProps = {
    activeType: PostType;
    activeService: ServiceType;
    setActiveService: (service: ServiceType) => void;
};

const ServiceButtons: FC<ServiceButtonsProps> = ({ activeType, activeService, setActiveService }) => {

    const activeServices = services[activeType];
    useEffect(() => {
        switch (activeType) {
            case 'image':
                setActiveService('Quality');
                break;
            case 'voice':
                setActiveService('Letters');
                break;
            case 'video':
                setActiveService('Quality'); 
                break;
            default:
                break;
        }
    }, [activeType, setActiveService]); 

    return (
        <div className={serviceButtonsStyles.serviceWrapper}>
            {(Object.entries(activeServices) as [ServiceType, string][])
            .filter(([key]) => key !== 'None')
            .map(([key, label]) => (
                <button 
                    key={key} 
                    className={key === activeService ? serviceButtonsStyles.active : ''}
                    onClick={() => setActiveService(key)}
                >
                    {label}
                </button>
            ))}
        </div>
    );
}

export default ServiceButtons;