export type TabType = 'KirocPosts' | 'UserPosts';
export type PostType = 'image' | 'voice' | 'video';
export type ServiceType = 'Quality' | 'Color' | 'Letters' | 'None';
export const tabs = {
    KirocPosts: { name: 'キロクから', types: { image: '写真', voice: '音声', video: 'ビデオ' } },
    UserPosts: { name: 'あなたから', types: { image: '写真', voice: '音声', video: 'ビデオ'} }
};
export const services = {
    image: { Quality: '高画質化', Color: 'カラー化', None: 'なし' },
    voice: { Letters: '再現', None: 'なし' },
    video: { Quality: '高画質化', Letters: '再現', None: 'なし' }
};