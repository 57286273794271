import { createSlice } from '@reduxjs/toolkit';

export const fileTypeSlice = createSlice({
    name: 'fileType',
    initialState: null,
    reducers: {
        setFileType: (state, action) => action.payload,
        resetFileType: () => null
    }
});

export const { setFileType, resetFileType } = fileTypeSlice.actions;
export default fileTypeSlice.reducer;
