import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../common/reducers';
import headerStyles from './header_styles.module.scss';
import header_logo from "../img/header_logo.png";
import { Link, useLocation } from 'react-router-dom';
import Navigation from '../navigation/navigation';
import { AiOutlineShoppingCart } from 'react-icons/ai';

const Header: FC = () => {

    const cartItems = useSelector((state: RootState) => state.cart.items);
    const location = useLocation();

    const getLinkPath = (basePath: string) => {
        const currentPath = location.pathname;
        const isAtBasePath = currentPath.endsWith(basePath) || currentPath.endsWith(`${basePath}/`);
        return isAtBasePath ? '/' : `/${basePath}`;
    };

    return (
        <header>
            <div className={headerStyles.content}>
                <Link to='/'><img src={header_logo} alt="Header Logo" /></Link>
                {cartItems.length > 0 && (
                    <div className={headerStyles.iconWrapper}>
                        <Link to={getLinkPath('cart')}>
                            <AiOutlineShoppingCart size={28} title='購入カゴ' className={headerStyles.icon} />
                        </Link>
                    </div>
                )}
                <Navigation />
            </div>
        </header>
    );
}

export default Header;
