import React, { FC, useState, useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../common/reducers';
import { Product, addToCart } from '../../common/reducers/cartSlice';
import { ImageModal, VoiceModal, VideoModal } from './modal';
import { TabType, PostType, ServiceType, tabs, services } from '../constants/constants';
import displayStyles from './display_styles.module.scss';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { BiDownload } from 'react-icons/bi';
import { PiFileAudioDuotone, PiFileVideoDuotone } from 'react-icons/pi';
import { functions } from '../../firebase';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import BodyLoading from '../../loading/bodyLoading';
import { useDispatch } from 'react-redux';
import { resetUploaded } from '../../common/reducers/uploadSlice';
import { resetFileType } from '../../common/reducers/fileTypeSlice';

type DisplayProps = {
    activeTab: TabType;
    activeType: PostType;
    activeService: ServiceType;
};

const Display: FC<DisplayProps> = ({ activeTab, activeType, activeService }) => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const isNavOpen = useSelector((state: RootState) => state.nav.isNavOpen);
    const uploaded = useSelector((state: RootState) => state.upload.uploaded);
    const fileType = useSelector((state: RootState) => state.fileType);
    const [files, setFiles] =  useState<FileData[]>([]);
    const [cachedFiles, setCachedFiles] = useState<CachedFiles>({});
    const [currentDownloadURL, setCurrentDownloadURL] = useState<string | null>(null);
    const [downloadURLs, setDownloadURLs] = useState<{ [key: string]: string }>({});
    const [isHighOrColorFile, setIsHighOrColorFile] = useState<string | null>(null);
    
    type FileData = {
        path: string;
        date: {
            _seconds: number;
            _nanoseconds: number;
        } | string;
        access: boolean;
        thumbnail: string;
        qualified: boolean,
        colored: boolean,
    };

    type CachedFile = {
        path: string;
        date: string;
        access: boolean;
        thumbnail: string;
        qualified: boolean;
        colored: boolean;
    };
    type CachedFiles = { [key: string]: CachedFile[] };

    type GetFilesResponse = {
        paths: string[];
        dates: string[];
        accesses: boolean[];
        thumbnails: string[];
        qualifieds: boolean[];
        coloreds: boolean[];
    }; 

    type GetFileDownloadURLResponse = {
        downloadURL: string;
    };

    const getFileIcon = (type: PostType, thumbnail: string | null): ReactNode => {
        const baseProps = { size: 72, style: { cursor: 'pointer' } };
        
        if (thumbnail) {
            return <img src={thumbnail} alt="thumbnail" style={{ width: 72, height: 72, cursor: 'pointer' }} />;
        } else {
            switch (type) {
                case 'voice':
                    return <PiFileAudioDuotone {...baseProps} title="voice" />;
                case 'video':
                    return <PiFileVideoDuotone {...baseProps} title="video" />;
                default:
                    return null;
            }
        }
    };

    const formatDate = (dateObject: Date): string => `${dateObject.getFullYear()}/${dateObject.getMonth() + 1}/${dateObject.getDate()}`;

    const fetchDownloadURL = async (path: string): Promise<string> => {
        if (downloadURLs[path]) return downloadURLs[path];

        const targetFile = files.find(file => file.path === path);

        if (targetFile && !targetFile.access) {
            const updateFileAccess = httpsCallable(functions, 'updateFileAccess');
            await updateFileAccess({ path });

            setFiles(prevFiles => {
                return prevFiles.map(file => {
                    if (file.path === path) {
                        return {
                            ...file,
                            access: true
                        };
                    }
                    return file;
                });
            });
        }

        const getFileDownloadURL = httpsCallable(functions, 'getFileDownloadURL');
        const result = await getFileDownloadURL({ path });
        const response = result.data as GetFileDownloadURLResponse;
        const downloadURL = response.downloadURL;

        setDownloadURLs(prev => ({ ...prev, [path]: downloadURL }));
        return downloadURL;
    };

    const handleFileOrNameClick = async (path: string) => {
        setIsLoading(true);

        try {
            const downloadURL = await fetchDownloadURL(path);
            setCurrentDownloadURL(downloadURL);
        } catch (error) {
            ////console.error(error);
        } finally {
            setIsLoading(false); 
        }
    };

    const handleDownloadClick = async (path: string) => {
        try {
            const downloadURL = await fetchDownloadURL(path);
            const responseFetch = await fetch(downloadURL);
            const blob = await responseFetch.blob();
            const blobURL = URL.createObjectURL(blob);

            const downloadLink = document.createElement('a');
            downloadLink.href = blobURL;
            downloadLink.download = path.split('/').pop() || '';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            URL.revokeObjectURL(blobURL);
        } catch (error) {
            ////console.error(error);
        }
    };

    const handleHighOrColor = (path: string) => {
        setIsHighOrColorFile(prevPath => prevPath === path ? null : path);
    };

    const getProductId = (type: string, plan: string): number => {
        if (type === 'image' && plan === 'quality') {
            return 5;
        } else if (type === 'image' && plan === 'color') {
            return 6;
        } else if (type === 'video' && plan === 'quality') {
            return 8;
        } else {
            return 0;
        }
    };

    const getProductName = (type: string, plan: string): string => {
        if (type === 'image' && plan === 'quality') {
            return '写真の高画質化';
        } else if (type === 'image' && plan === 'color') {
            return '写真のカラー化';
        } else if (type === 'video' && plan === 'quality') {
            return 'ビデオの高画質化';
        } else {
            return '';
        }
    };

    const handleAddToCart = (plan: string, thumbnail?: string) => {
        const productToAdd: Product = {
            id: getProductId(activeType, plan),
            name: getProductName(activeType, plan),
            quantity: 1, 
            paths: isHighOrColorFile ? [isHighOrColorFile] : [],
            thumbnail, 
        };
        dispatch(addToCart(productToAdd));
    
        setIsHighOrColorFile(null);
    };

    /*const updateFileProperty = (filePath: string, property: 'qualified' | 'colored') => {
        const updatedFiles = files.map(file => {
            if (file.path === filePath) {
                return {
                    ...file,
                    [property]: true
                };
            }
            return file;
        });
    
        setFiles(updatedFiles);
    };*/

    useEffect(() => {
        const cacheKey = fileType
        ? `${activeTab}-${fileType}-${activeService}` 
        : `${activeTab}-${activeType}-${activeService}`;
        
        if (
            !tabs[activeTab]?.types[activeType] ||
            !(services[activeType] as Record<ServiceType, string>)[activeService] ||
            (activeTab === 'KirocPosts' && activeService === 'None') ||
            (activeTab === 'UserPosts' && activeService !== 'None')
        ) {
            return;
        }

        if (fileType) {
            setCachedFiles(prev => {
                const newCache = { ...prev };
                delete newCache[cacheKey];
                return newCache;
            });
            //console.log('cashed')
        }
    
        if (cachedFiles[cacheKey] && !uploaded) {
            setFiles(cachedFiles[cacheKey]);
            return;
        }

        const getFiles = httpsCallable(functions, 'getFiles');
        getFiles({
            tab: activeTab,
            type: activeType,
            service: activeService,
        })
            .then((result) => {
                const response = result.data as GetFilesResponse;
                const combinedData = response.paths.map((path, index) => ({
                    path,
                    date: response.dates[index],
                    access: response.accesses[index],
                    thumbnail: response.thumbnails[index],
                    qualified: response.qualifieds[index],
                    colored: response.coloreds[index],
                }));

                setFiles(combinedData);
                if (!fileType) {
                    setCachedFiles(prev => ({ ...prev, [cacheKey]: combinedData }));
                }
                dispatch(resetUploaded());
                dispatch(resetFileType());
                setIsLoading(false);
                //console.log('getFiles');
            })
            .catch((error) => {
                //console.error(error);
            });
    }, [activeTab, activeType, activeService, uploaded, fileType]);

    if (isNavOpen) return null;

    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <>
            <div className={displayStyles.content}>
                {files.map((file, index) => {
                    const formattedDate = typeof file.date === 'string' 
                        ? file.date 
                        : formatDate(new Date(file.date._seconds * 1000));
        
                    return (
                        <div key={file.path} className={displayStyles.block}>
                            {!file.access && <div className={displayStyles.newLabel}>New</div>}
                            <div onClick={() => handleFileOrNameClick(file.path)}>
                                {getFileIcon(activeType, file.thumbnail)}
                            </div>
                            <p 
                                className={displayStyles.fileName} 
                                onClick={() => handleFileOrNameClick(file.path)}
                            >
                                {file.path.split('/').pop()}
                            </p>
                            <div className={displayStyles.element}>
                                <p className={displayStyles.date}>{formattedDate}</p>
                                {(file.qualified === false || file.colored === false) && (
                                    <p>
                                        <BsThreeDotsVertical 
                                            size={16} 
                                            title="詳細" 
                                            className={displayStyles.icon} 
                                            onClick={() => handleHighOrColor(file.path)}
                                        />
                                    </p>
                                )}
                                <p>
                                    <BiDownload
                                        size={22} 
                                        title="download" 
                                        className={displayStyles.icon} 
                                        onClick={() => handleDownloadClick(file.path)}
                                    />
                                </p>
                            </div>
                            {isHighOrColorFile === file.path && (
                                <div className={displayStyles.highOrColorOverlay}>
                                    {file.qualified === false && (
                                        <button onClick={() => {
                                            //updateFileProperty(file.path, 'qualified');
                                            handleAddToCart('quality', file.thumbnail);
                                        }}>
                                            高画質化
                                        </button>
                                    )}
                                    {activeType === 'image' && file.colored === false && (
                                        <button onClick={() => {
                                           //updateFileProperty(file.path, 'colored');
                                            handleAddToCart('color', file.thumbnail);
                                        }}>
                                            カラー化
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>

            {currentDownloadURL && (
                activeType === 'image' ? <ImageModal downloadURL={currentDownloadURL} onClose={() => setCurrentDownloadURL(null)} /> :
                activeType === 'voice' ? <VoiceModal downloadURL={currentDownloadURL} onClose={() => setCurrentDownloadURL(null)} /> :
                activeType === 'video' ? <VideoModal downloadURL={currentDownloadURL} onClose={() => setCurrentDownloadURL(null)} /> :
            null
            )}
        </>
    );
}

export default Display;
