import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { functions } from '../../firebase';
import { httpsCallable } from 'firebase/functions';
import BodyLoading from '../../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import changeStyles from './change_styles.module.scss';

const AddressChange: FC = () => {

    const [currentAddress, setCurrentAddress] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [confirmAddress, setConfirmAddress] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const getAddress = httpsCallable(functions, 'getAddress');
        getAddress()
            .then((result) => {
                const data = (result.data as { address: string }).address;
                setCurrentAddress(data);
            })
            .catch((error) => {
                //error(error);
            });
    }, []);

    const handleChangeAddress = (inputAddress: string) => {
        if (!address) {
            setErrorMessage("新しい住所を入力して下さい。");
            return;
        }

        if (address !== confirmAddress) {
            setErrorMessage("入力された住所と確認用の住所が一致しません。");
            return;
        }

        setIsLoading(true);
        const updateAddress = httpsCallable(functions, 'updateAddress');
        updateAddress({ address: inputAddress })
            .then((result) => {
                //console.log(result)

                const updateAddressToSheet = httpsCallable(functions, 'updateAddressToSheet');
                updateAddressToSheet({ address })
                    .then((result) => {
                        //console.log(result.data);
                        setCurrentAddress(address);
                        setAddress('');
                        setConfirmAddress('');
                    })
                    .catch((error) => {
                        //console.error("Error:", error); 
                    });

            })
            .catch((error) => {
                //console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    if (!currentAddress || isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="20vh" />;

    return (
        <div className={changeStyles.content}>
            <div className={changeStyles.subjectAndClose}>
                <h1 className={changeStyles.subject}>住所の変更</h1>
                <Link to="/">
                    <IoCloseSharp 
                        size={28} 
                        title="閉じる" 
                        className={changeStyles.closeButton}
                    />
                </Link>
            </div>
            <div className={changeStyles.form}>
                <div className={changeStyles.block}>
                    <p className={changeStyles.current}>現在の住所</p>
                    <p className={changeStyles.currentValue}>{currentAddress}</p>
                </div>
                <input 
                    type="text" 
                    value={address} 
                    onChange={(e) => {setErrorMessage(''); setAddress(e.target.value)}} 
                    placeholder="新しい住所"
                />
                <input 
                    type="text" 
                    value={confirmAddress} 
                    onChange={(e) => {setErrorMessage(''); setConfirmAddress(e.target.value)}} 
                    placeholder="新しい住所の確認"
                />
                {errorMessage && <p className={changeStyles.error}>{errorMessage}</p>}  
                <button onClick={() => handleChangeAddress(address)}>変更</button>
            </div>
        </div>
    );
}

export default AddressChange;
