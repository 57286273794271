import React, { FC, useState } from 'react';
import { auth, functions } from '../firebase';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions'
import { Link } from 'react-router-dom';
//import { Product, addToCart } from '../common/reducers/cartSlice';
//import { useDispatch } from 'react-redux';
import BodyLoading from '../loading/bodyLoading';
import { IoCloseSharp } from 'react-icons/io5';
import lettersStyles from './letters_styles.module.scss';
import commonStyles from '../common/common_styles.module.scss';
import loginStyles from '../login/login_styles.module.scss';

const Letters: FC = () => {
    //connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const [isMessage, setIsMessage] = useState<string>('');
    const [voiceDeadQuantity, setVoiceDeadQuantity] = useState<number>(0);
    const [videoDeadQuantity, setVideoDeadQuantity] = useState<number>(0);
    const [voiceAliveQuantity, setVoiceAliveQuantity] = useState<number>(0);

    //const dispatch = useDispatch();

    /*const handleAddToCart = (product: Product) => {
        if (product.quantity > 0) {
            dispatch(addToCart(product));

            setVoiceLetterQuantity(0);
            setVideoLetterQuantity(0);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };*/
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);
    const formattedDate = `${year}年${month}月${day}日`;

    const handleApply = async () => {
        setIsLoading(true);
        const user = auth.currentUser;
        if (user === null) return;

        const items = [];

        if (voiceDeadQuantity >= 1) {
            items.push({
                id: 7,
                name: '故人の声',
                quantity: voiceDeadQuantity || 0,
            });
            setVoiceDeadQuantity(0);
        }

        if (videoDeadQuantity >= 1) {
            items.push({
                id: 9,
                name: '故人の姿',
                quantity: videoDeadQuantity || 0,
            });
            setVideoDeadQuantity(0);
        }

        if (voiceAliveQuantity >= 1) {
            items.push({
                id: 10,
                name: '現在の声',
                quantity: voiceAliveQuantity || 0,
            });
            setVoiceAliveQuantity(0);
        }

        const applyData = {
            date: formattedDate,
            email: user.email,
            items: items
        };

        try {
            const applyToMail = httpsCallable(functions, 'applyToMail')
            await applyToMail({ applyData });
            setIsMessage('申し込みを受け付けました。3日後までにご連絡させていただきます。');
        } catch (error) {
            //console.error("Error creating applyToMail: ", error);
            setIsMessage('申し込みに失敗しました。再度お試しください。');
        }
        setIsFinished(true);
        setIsLoading(false);
    }

    if (isLoading) return <BodyLoading backgroundColor="#fff" paddingTop="16vh" />;

    return (
        <div className={lettersStyles.lettersContent}>
            {isFinished && (
                <div className={`${commonStyles.bodyOverlapCenter}`} style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                    <div className={loginStyles.loginForm}>
                        <p>{isMessage}</p>
                        <button onClick={() => {setIsFinished(false)}}>閉じる</button>
                    </div>
                </div>
            )}
            <Link to="/">
                <IoCloseSharp 
                    size={28} 
                    title="閉じる" 
                    className={lettersStyles.closeButton}
                />
            </Link>
            <ul>
                <li><span><a href="https://kiroc.net/services/#sound_letter" target="_blank" rel="noopener noreferrer">AIが創る故人の声</a></span></li>
                <li><span><a href="https://kiroc.net/services/#preserve_current_voice" target="_blank" rel="noopener noreferrer">AIが保存する現在の声</a></span></li>
                <li><span><a href="https://kiroc.net/services/#video_letter" target="_blank" rel="noopener noreferrer">AIが創る故人の姿</a></span></li>
            </ul>
            <div className={lettersStyles.block}>
                <div>
                    <p className={lettersStyles.subject}>AIが創る故人の声</p>
                    <input 
                        type="number" 
                        className={lettersStyles.value}
                        defaultValue={voiceDeadQuantity}
                        onChange={(e) => setVoiceDeadQuantity(parseInt(e.target.value))}
                        placeholder="枚数"
                        min="1"
                    />
                    {/*{voiceLetterQuantity >= 1 && (
                        <button 
                            className={lettersStyles.cartButton} 
                            //onClick={() => handleAddToCart({id: 7, name: '音声レター', quantity: voiceLetterQuantity})}
                            onClick={handleApply}
                        >
                            申し込む
                        </button>
                    )}*/}
                </div>
                <div>
                    <p className={lettersStyles.subject}>AIが保存する現在の声</p>
                    <input 
                        type="number" 
                        className={lettersStyles.value}
                        defaultValue={voiceAliveQuantity}
                        onChange={(e) => setVoiceAliveQuantity(parseInt(e.target.value))}
                        placeholder="枚数"
                        min="1"
                    />
                </div>
                <div>
                    <p className={lettersStyles.subject}>AIが創る故人の姿</p>
                    <input 
                        type="number" 
                        className={lettersStyles.value}
                        defaultValue={videoDeadQuantity}
                        onChange={(e) => setVideoDeadQuantity(parseInt(e.target.value))}
                        placeholder="枚数"
                        min="1"
                    />
                </div>
                {(voiceDeadQuantity >= 1 || voiceDeadQuantity >= 1 || videoDeadQuantity >= 1) && (
                    <>
                        <button 
                            className={lettersStyles.cartButton} 
                            onClick={handleApply}
                        >
                            申し込む
                        </button>
                        <p>※ 申し込みをしても注文は確定しません。料金等は後日ご連絡させていただきます。</p>
                    </>
                )}
            </div>
        </div>
    );
}

export default Letters;
